<template>
  <button type="submit" :disabled="disabled || loading" class="border enabled:border-2 bg-black text-white hover:bg-zinc-100 hover:text-black dark:bg-gray-100 dark:text-black dark:hover:bg-zinc-800 dark:hover:text-white cursor-pointer transition rounded p-2 w-1/2 disabled:opacity-50 dark:disabled:bg-zinc-400 dark:disabled:text-gray-900 dark:disabled:border-zinc-800 enabled:border-gray-500 enabled:font-medium flex items-center justify-center">
    {{ text }}
    <Loader v-if="loading" class="mr-2" />
  </button>
</template>

<script>
import Loader from './Loader.vue'
export default {
  name: 'LoaderComponent',
  components: {
    Loader
  },
  props: {
    disabled: {
      required: false,
      type: Boolean,
      default: false
    },
    loading: {
      required: false,
      type: Boolean,
      default: false
    },
    text: {
      required: true,
      type: String,
      default: 'Submit'
    },
  }
}
</script>

<style lang="scss" scoped>
.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  height: 60px;

  position: relative;

  .loader {
    transform: scale(0.8);
    position: absolute;
    right: 10px;
  }
}
</style>
